export const commonEnvironment = {
  defaultTenant: 3550,
  messageCenter: {
    counterRefreshTime: 30000,
    messageRefreshTime: 30000,
    perPage: 8,
  },
  planning: {},
  shouldPollForUpdates: false,
  version: '0.0.0',
  authServerClient: 'resource-planner-client',
  workJournal: {
    refreshTime: 60000, // 60s
  },
  planningGrid: {
    autoRefreshTime: 3600000, // 1h
  },
  tabletScreenSize: 1024,
  enableRealtimeConnection: true,
  defaultDailyWorkingHours: 8.5,
  rowDefaultHeight: 2,
  rowExpandMinHeight: 6,
  authScope: 'openid email profile offline_access',
};
