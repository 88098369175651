import { commonEnvironment } from './common.environment';

export const environment = {
  ...commonEnvironment,
  production: true,
  sentry: 'https://b9f0b14f8c8e4697ab2e363fdcc908ad@o296516.ingest.sentry.io/6269469',
  envName: 'prod',
  serverApiUrl: 'https://planning.mlink.glb.ch',
  authServerUrl: 'https://auth.cloud.triarc-labs.com/auth',
  bearerExcludedUrls: [],
  activatedTenants: [3550, 3645, 3752, 3751, 3551],
};
